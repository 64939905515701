<template>
    <div>
        <!--购物车有内容-->
        <div class="cart-wrapper" v-if="Object.keys(shopCart).length > 0">
            <!--购物车头部信息-->
            <div class="cart-head-wrapper">
                <div>共{{productCount}}个商品</div>
                <!--                <div>编辑</div>-->
            </div>
            <!--购物车商品列表-->
            <div class="cart-product-list">
                <div v-for="item1,key1 in shopCart" :key="key1">
                    <div class="cart-product-item" v-for="item2,key2 in item1" :key="key2">
                        <div :class="['cart-product-tick',item2.tick == true? 'tick-active' :'tick-default']" @click="clickProductTick(key1,key2)">
                            <img class="tick" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%B4%AD%E7%89%A9%E8%BD%A6%E6%89%93%E5%8B%BE.png">
                        </div>
                        <img class="cart-product-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2.img">
                        <div class="cart-product-info">
                            <div class="cart-product-base-info">
                                <div class="cart-product-base-info-top">
                                    <div class="cart-product-name">{{item2.title}}</div>
                                    <img class="cart-product-delete" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%B4%AD%E7%89%A9%E8%BD%A6%E5%88%A0%E9%99%A4%E6%8C%89%E9%92%AE.png" @click="deleteProduct(key1,key2)">
                                </div>
                                <div class="cart-product-base-info-footer">规格：{{item2.specs}}</div>
                            </div>
                            <div class="cart-number-price">
                                <div class="cart-product-price-wrapper">
                                    <div>￥{{moneyFen2Yuan(item2.price)}}</div>
                                    <div>￥{{moneyFen2Yuan(item2.orgPrice)}}</div>
                                </div>
                                <van-stepper v-model="item2.number" :max="item2.buylimit" @click.native="clickStepper"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;height: 4rem;"></div>
            </div>
            <!--购物车结算栏-->
            <div class="cart-product-order-wrapper">
                <div class="order-left-wrapper">
                    <div :class="['cart-product-tick',allTick === true ? 'tick-active' : 'tick-default']" @click="clickAllTick">
                        <img class="tick" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%B4%AD%E7%89%A9%E8%BD%A6%E6%89%93%E5%8B%BE.png">
                    </div>
                    <div>全选</div>
                </div>
                <div class="order-right-wrapper">
                    <div>总计：￥{{totalPrice}}</div>
                    <div class="order-button" @click="submitOrder">结算</div>
                </div>
            </div>
        </div>
        <!--购物车为空-->
        <div class="empty-wrapper" v-else>
            <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%B4%AD%E7%89%A9%E8%BD%A6%E6%B2%A1%E6%9C%89%E4%B8%9C%E8%A5%BF.png">
            <div>购物车没有商品，快去买点什么吧<p style="color: #346cc9" @click="toShop">前往</p></div>
        </div>
        <!--导航栏-->
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import { getShopCart, removeShopCart, updateShopCart, cartPay } from '../../utils/cart'
  import { moneyFen2Yuan } from '../../utils/tools'
  import NavBar from '../../components/common/NavBar'
  export default {
    name: 'Cart',
    components: { NavBar },
    // components: { NavBar },
    data () {
      return {
        shopCart: [],
        allTick: false
      }
    },
    methods: {
      moneyFen2Yuan,
      // 点击商品的打勾
      clickProductTick: function (businessId, productId) {
        this.allTick = false
        const lastTick = this.shopCart[businessId][productId].tick
        if (lastTick === true) {
          this.shopCart[businessId][productId].tick = false
        } else {
          this.shopCart[businessId][productId].tick = true
        }
        this.freshShopCart()
      },
      // 全部勾选
      clickAllTick: function () {
        if (this.allTick === true) {
          for (const i1 in this.shopCart) {
            for (const i2 in this.shopCart[i1]) {
              this.shopCart[i1][i2].tick = false
            }
          }
          this.allTick = false
        } else {
          for (const i1 in this.shopCart) {
            for (const i2 in this.shopCart[i1]) {
              this.shopCart[i1][i2].tick = true
            }
          }
          this.allTick = true
        }
        this.freshShopCart()
      },
      clickStepper: function () {
        this.freshShopCart()
      },
      // 删除
      deleteProduct: function (businessId, productId) {
        this.$dialog.confirm({
          title: '提示',
          message: '确定删除吗'
        }).then(() => {
          removeShopCart(businessId, productId)
          this.shopCart = getShopCart()
        })
      },
      // 刷新购物车
      freshShopCart: function () {
        updateShopCart(this.shopCart)
      },
      toShop: function () {
        this.$router.push('home')
      },
      submitOrder: function () {
        // let res = 0
        let number = 0
        for (var i in this.shopCart) {
          for (var i2 in this.shopCart[i]) {
            console.log(this.shopCart[i][i2])
            if (this.shopCart[i][i2].tick === true) {
              // res = 1
              number++
            }
          }
        }
        if (number <= 0) {
          this.$toast('请先选择商品')
        } else if (number > 9) {
          this.$toast('单次购买不要超过9个品类')
        } else {
          cartPay()
          this.$router.push('/goodsOrder')
        }
      }
    },
    computed: {
      // 计算购物车商品数量
      productCount: function () {
        let count = 0
        for (const i1 in this.shopCart) {
          for (const i2 in this.shopCart[i1]) { // eslint-disable-line no-unused-vars
            count++
          }
        }
        return count
      },
      totalPrice: function () {
        let total = 0.00
        for (const i1 in this.shopCart) {
          for (const i2 in this.shopCart[i1]) {
            if (this.shopCart[i1][i2].tick === true) {
              let price = 0
              price = this.shopCart[i1][i2].price * this.shopCart[i1][i2].number
              total = total + price
            }
          }
        }
        return this.moneyFen2Yuan(total)
      }
    },
    mounted () {
      this.shopCart = getShopCart()
    }
  }
</script>

<style scoped>
    .cart-wrapper {
    }
    .cart-head-wrapper {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        font-size: 1.1rem;
        padding: 1rem 0;
        /*align-self: ;*/
    }
    .cart-head-wrapper div:first-child {
        color: #999999;
    }
    .cart-head-wrapper div:nth-child(2) {
        font-weight: 600;
    }
    .cart-product-list {
        display: flex;
        flex-flow: column;
    }
    .cart-product-item {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        margin-bottom: 0.5rem;
    }
    .cart-product-tick  {
        border-radius: 10rem;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    .tick-active {
        background-color: #F64C4C;
    }
    .tick-default {
        background-color: #a8a8a8;
    }
    .tick {
        width: 1rem;
    }
    .cart-product-img{
        width: 22%;
    }
    .cart-product-info {
        width: 65%;
        height: 6rem;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .cart-product-base-info {
        display: flex;
        flex-flow: column;
    }
    .cart-product-base-info-top {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }
    .cart-product-name {
        width: 70%;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;
        font-weight: 600;
    }
    .cart-product-delete {
        width: 1.1rem;
        height: 1.1rem;
    }
    .cart-number-price {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }
    .cart-product-base-info-footer {
        color: #999999;
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }
    .cart-product-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
    }
    .cart-product-price-wrapper div:first-child {
        font-size: 1rem;
        color: #F64C4C;
    }
    .cart-product-price-wrapper div:nth-child(2) {
        font-size: 0.8rem;
        color: #999999;
        text-decoration: line-through;
        margin-left: 0.3rem;
    }
    .cart-product-order-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom: 4.5rem;
        box-sizing: border-box;
        padding: 1rem 1.5rem;
    }
    .order-left-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .order-left-wrapper > div:nth-child(2) {
        font-size: 1rem;
        color: #999999;
        margin-left: 1rem;
    }
    .order-right-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .order-right-wrapper > div:first-child {
        margin-right: 1rem;
        font-weight: 600;
    }
    .order-button {
        background-color: #F64C4C;
        color: #ffffff;
        padding: 0.4rem 1.5rem;
        border-radius: 0.5rem;
    }
    .empty-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-top: 8rem;
    }
    .empty-wrapper > img {
        width: 12rem;
    }
    .empty-wrapper > div {
        margin-top: 2rem;
        font-size: 1rem;
        color: #999999;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
</style>
